
import { defineComponent, computed } from "vue";
import { useStore } from "../store";

export const RegulationTextComponent = defineComponent({
  setup() {
    const store = useStore();

    const appType = computed(() => store.state.runtimeConfig.type);
    const company = computed(() => {
      switch (appType.value) {
        case "GENERAL": {
          return {
            name: "Doc4You Sp. z o. o.",
            date: "01.02.2021",
          };
        }
        case "EUROP_ASSISTANCE": {
          return {
            name: "Europ Assistance Polska Sp. z o. o.",
            date: "01.02.2021",
          };
        }
        case "MONDIAL": {
          return {
            name: "AWP Polska Sp. z o.o.",
            date: "17.02.2021",
          };
        }
        case "PZU": {
          return {
            name: "PZU ZDROWIE",
            date: "24.11.2020 r.",
          };
        }
        case "INTER": {
          return {
            name: "Doc4You Sp. z o. o.",
            date: "23.03.2020 r.",
          };
        }
        case "ALLIANZ": {
          return {
            name: "Allianz",
            date: "27.04.2021 r.",
          };
        }
        case "SALTUS": {
          return {
            name: "Saltus",
            date: "27.05.2021 r.",
          };
        }
        case "TU_ZDROWIE": {
          return {
            name: "Tu Zdrowie",
            date: "31.08.2021 r.",
          };
        }
        case "LUXMED": {
          return {
            name: "LUX MED Sp. z o.o.",
            date: "21.06.2022 r.",
          };
        }
      }
      return {
        name: "Doc4You Sp. z o. o.",
        date: "...",
      };
    });
    return {
      appType,
      company,
    };
  },
});

export default RegulationTextComponent;
